import { images } from "../../assets/images/images";

export const MainScreenData = {
    Links: [
        {
            text: "השירותים שלנו",
            onPress: null,
            pageName: "OurServices"
        },

        {
            text: "תובנות והמלצות",
            onPress: null,
            pageName: "InsightAndRecommendetion"
        },
        {
            text: "למה אנחנו?",
            onPress: null,
            pageName: "WhyFolloApp"
        },
        {
            text: "שאלות ותשובות",
            onPress: null,
            pageName: "QuestionsAndAnswer"
        },
    ],
    Login: {
        Agent: {
            text: "כניסת סוכנים",
            onPress: null
        },
        Users: {
            text: "כניסת משתמשים",
            onPress: null
        },
    },
    Page3: [
        {
            title: 'חסכון במשכנתא',
            content: 'ייעוץ לבחירת משכנתא, שיפור התנאים במשכנתא קיימת, מחזור משכנתא ועוד',
            image: images.IconImages.MortagageSaving,
            height: 160
        },
        {
            title: 'השקעה חכמה',
            content: 'התאמה אישית של מוצרי חסכון והשקעה: פקדונות, תיקי השקעות, פוליסות חסכון וקרנות גמל להשקעה',
            image: images.IconImages.SmartInvestment,
            height: 160
        },
        {
            title: 'תכנון ובקרה פיננסית',
            content: 'לתכנן תקציב, יעדים משפחתיים, הכנסות והוצאות חודשיות ולנהל טוב יותר את התזרים',
            image: images.IconImages.FinancialPlanning,
            height: 160
        },
        {
            title: 'החזרי מס',
            content: 'לקבל את החזרי המס שמגיעים לך, בתהליך פשוט וקצר',
            image: images.IconImages.TaxReturn,
            height: 160
        },
        {
            title: 'טיפול במוצרים פנסיונים',
            content: 'בחירה של מסלולי קרן הפנסיה וביטוח המנהלים המתאימים ביותר עבורך',
            image: images.IconImages.PensionProduct,
            height: 160
        },
        {
            title: 'חסכון בביטוח',
            content: 'ליהנות מליווי לבחירת ביטוח שייתן מענה מיטבי לצרכים שלך, בתנאים הטובים ביותר',
            image: images.IconImages.InsuranceSaving,
            height: 160
        },
    ],
    Page5Data: [
        {
            title: '360 מעלות',
            content: 'לקבל תמונת מצב מלאה ועדכנית על הפעילות הפיננסית שלך',
        },
        {
            title: 'מותאם אישית',
            content: 'ליהנות מתובנות והמלצות שהותאמו אישית עבורך',
        },
        {
            title: 'שמים אותך במרכז',
            content: 'העובדה שמדובר בגוף עצמאי מאפשרת לנו לדאוג רק לאינטרסים שלך',
        },
        {
            title: 'חדשני',
            content: 'מערכת חכמה שמנתחת את הפעילות הפיננסית שלך, עוקבת אחר השינויים והמגמות בשוק, מפיקה תובנות וממליצה על צעדים נדרשים',
        },
        {
            title: 'פשוט ויעיל ',
            content: 'ממשק נגיש ונוח שמאפשר לך להתעדכן, להתייעץ ולקבל החלטות מושכלות - בלחיצת כפתור',
        },
        {
            title: '100% מוגן',
            content: 'פיקוח וניטור שוטפים ע”י רשות ני”ע, בנק ישראל ורשות שוק ההון, מאפשרים לנו לעמוד בתנאים מחמירים של אבטחת מידע ושמירת נתונים',
        },
    ],
    Page6Data: [
        {
            title: 'באלו תחומים אתם יכולים לסייע לי?',
            content: 'ריכוז המידע הפיננסי שלך בצורה מסודרת במקום אחד, מתן התראות חשובות לייעול  ההתנהלות הפיננסית ואפשרות להתייעצות עם מומחים להשגת היעדים הכלכליים הרצויים ויישומן.',
        },
        {
            title: 'אלו סוגי התראות אני עשוי לקבל?',
            content: 'ההתראות שלנו מתחלקות לארבעה סוגים: התראות העלאת ערך- כשמן, התראות אלו ניתנות כאשר המערכת מזהה אפשרות להעלאת ערך התיק.\n לדוגמא זיהוי תשואות חסר של הקופה שלך השנה (נעשית על ידי השוואת הקופה שלך לממוצע שאר הקופות) התראות חוסר- התראות אלו ניתנות כאשר אנו מזהים חוסר ביטוחי שנראה לנו חשוב.\n לדוגמא, חוסר בכיסוי ביטוחי למחלות קשות התראות ליקוי- התראות אשר ניתנות כאשר אנו מזהים תקלה או בעיה אקוטית. לדוגמא, זיהוי של המערכת כי  המעסיק הפסיק להפקיד לקופת הפנסיה.\n התראות כלליות- דיווחים שוטפים על פעילות פיננסית. לדוגמא, הוספת פוליסה ( "המערכת זיהתה כי לקחת משכנתא").',
        },
        {
            title: 'איזו אינפורמציה אתם אוספים ממני?',
            content: "אנחנו מבקשים גישה לשלושה מקורות מידע:\n 1. הר הביטוח וחברות הביטוח, אשר יחד נותנים לנו מידע על הביטוחים שלך (למשל ביטוחי חיים, בריאות, דירה וכו'\n 2. המסלקה הפנסיונית אשר מספקת מידע לגבי הנכסים הפנסיוניים הביטוחים.\n 3.  לשכת האשראי של בנק ישראל המספקת מידע לגבי דירוג האשראי  שלך(ציון מאפס עד אלף שמשקף את  ההיכולת שלך לעמוד בהתחייבויות הפיננסיות שלך)  ולגבי מבנה ההלוואות שלך.",
        },
        {
            title: 'לשם מה אתם אוספים אינפורמציה זו?',
            content: 'המידע שאנו אוספים מאפשר לנו להציג תמונה כוללת על המצב הפיננסי של הלקוח, להציף את הבעיות שעולות  מתוך ניתוח המידע, ולנסות להציע פתרונות מונחי אלגוריתמים בשילוב ייעוץ המומחים, וליישמם באישור הלקוח.',
        },
        {
            title: 'האם אני יכול לקבל אינפורמציה בלי לתת לכם אישור לאיסוף מידע?',
            content: 'לא, ללא הגישה למקורות המידע אין לנו נתונים שיאפשרו לנו לתת את השירות שאנו מציעים',
        },
        {
            title: 'תחת איזה פיקוח אתם נמצאים?',
            content: 'אנחנו פועלים על פי חוק הגנת הפרטיות ונמצאים בפיקוח של הרשות לניירות ערך, בנק ישראל ורשות שוק ההון',
        },
        {
            title: 'מה אתם מקבלים בתמורה לשירות שאתם מציעים?',
            content: 'אנחנו מרוויחים כשהלקוח קונה מאיתנו מוצרים. מלבד הפלטפורמה שמאפשרת את איסוף הנתונים ומתן ההתראות, אנו מציעים המלצות. במידה ותרצה לרכוש מוצר, אנו נרוויח או מעמלת יצרנים (חברות הביטוח, נותני אשראי ומנהלי השקעות), או מהשירותים בתשלום המוצעים (מוצרי ניהול פיננסי וייעוצי מומחים של המערכת)',
        },
        {
            title: 'האם המידע שלי מועבר לגורם שלישי?',
            content: 'המידע שאנו אוספים מועבר אך ורק למומחים שאנו עובדים איתם, ורק כשאת/ה בוחר/ת בכך  במידה וברצונך להיעזר במומחים שלנו בתחומי ביטוחים, כלכלת המשפחה והלוואות ומשכנתאות. במידה ובהתאם להמלצות המומחה תרצה לרכוש מוצר ביטוחי חלופי, הנתונים הרלוונטים יועברו לגוף הרלוונטי לאחר אישורך.',
        },
        {
            title: 'מה קורה למידע שלי אם אני מחליט להסיר את האפליקציה?',
            content: 'לפי חוק אנו מחוייבים לשמור את הנתונים לפרק זמן המוגדר בחוק לצרכי פיקוח ובקרה, אך אנו לא עושים בו כל שימוש אחר.',
        },
        {
            title: 'האם הנתונים מתעדכנים מיידית ברגע שנתתי הרשאה לאיסוף נתונים?',
            content: 'חלק מהנתונים מתעדכנים תוך כמה דקות אולם לחלק מהמידע לוקח מספר ימים להתעדכן. אל דאגה, תקבל התראה ברגע שנתונייך יתעדכנו במערכת.',
        },
        {
            title: 'אפשר לצרף בן משפחה נוסף לחשבון שלי?',
            content: 'בשלב זה עדיין לא. ניתן להירשם באופן נפרד',
        },
        {
            title: 'איך המידע שלי נשמר במערכת?',
            content: 'פולואפ עומדת בכללי אבטחת מידע והגנת הפרטיות המחמירים ביותר בשוק ובהתאם לדרישות המחייבות של הרגולציה בתחום.',
        },
    ],
    FooterData: [
        {
            text: 'מי אנחנו?',
            pageName: "WhoWeAre"
        },

        {
            text: 'למה אנחנו?',
            pageName: "WhyFolloApp"
        },

        {
            text: 'תנאי שימוש',
            pageName: "TermsOfConditions"
        },

        {
            text: 'יצירת קשר',
            pageName: "Contact"
        },
        {
            text: 'השירותים שלנו',
            pageName: "OurServices"
        },

        {
            text: 'מדיניות הפרטיות',
            pageName: "PrivacyTerms"
        },
    ]
}